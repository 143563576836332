<template>
  <div style="min-height: 600px;">
    <div>
      <div class="has-background-secondary">
        <div class="container content py-6 mb-6">
          <h1 class="has-text-white my-0">Privacy</h1>
        </div>
      </div>

      <div class="container content pb-6">
        <div style="max-width: 850px">
        <p>
        SearchRockit™ search engine embodies the natural liberty that people desire which consists of being free from authoritarian censorship.
        In order to preserve liberty and provide uncensored anonymous search, therefore, we collect no data from our users, we sell no data of
        our users, replace no cookies, our algorithmand web index breaks through censorship.
        </p><p>
        Privacy Policy
        </p><p>
        SearchRockit™ search operates on https 256 bit encrypted web browsing at https://searchrockit.com and on both iPhone and Android apps.
        </p><p>
        SearchRockit™ does not set any cookies on your device.
        </p><p>
        SearchRockit™ does not keep any search logs.
        </p><p>
        SearchRockit™ does not sell your data because SearchRockit™ does not have any data about you or your web browsing history.
        </p><p>
        SearchRockit™ does not support or condone any criminal activity or that which may cause harm to any individual person, persons or property
        through the use of it services
        </p><p>
        SearchRockit™ may monitor it server logs from time to time to manage bandwidth and site performance. No personally identifiable information
        like your IP address is accessible or used in these audits.
        </p><p>
        SearchRockit™ does not protect you from malware, adwear or spyware that may already be installed on your device or that might be
        downloaded onto your device while browsing the Internet.
        </p><p>
        Third parties may be placing in reading cookies if you are using a traditional web browser that does not have the cookie blocking enabled.
        We highly recommend using the SearchRockit™ Wormhole Technology™ or a trustworthy VPN tunnel when browsing the Internet.
        </p><p>
        Browse the Internet at your own risk.
        </p><p>
        About your SearchRockit™ with Wormhole Technology™ App
        </p><p>
        SearchRockit™ with Wormhole Technology™, is a browser-less application that allows you to surf the web completely anonymously with
        absolutely zero record of your searches and sites you visit. When you use the SearchRockit™ with Wormhole Technology™, sites you visit are
        opened and viewable right through the Wormhole Technology™ App.
        </p><p>
        Our newsfeed is sourced from the RealClear Politics and streams real time directly from the RSS feed.
        </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>